.jour_calendrier {
    width: 45px;
    color: #ff580c;
    font-weight: bold;
    font-size: 14px;
    border-top: 1px solid #c7c7c7;
    border-right: 1px solid #c7c7c7;
    border-left: 1px solid #c7c7c7;
    border-bottom: 1px solid #ff580c;
    -webkit-border-top-left-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    -moz-border-radius-topleft: 3px;
    -moz-border-radius-topright: 3px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    display: block;
    text-align: center;
    padding-top: 1px;
    padding-bottom: 1px;
    background-color: #FFF;
}

.mois_calendrier {
    width: 45px;
    text-align: center;
    padding-top: 3px;
    padding-bottom: 3px;
    border-left: 1px solid #c7c7c7;
    border-right: 1px solid #c7c7c7;
    border-bottom: 1px solid #c7c7c7;
    -webkit-border-bottom-right-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    font-size: 12px;
    background: #ffd9cc;
}

.jour_calendrier_inactif {
    width: 45px;
    color: #3d3d3d;
    font-weight: bold;
    font-size: 14px;
    border-top: 1px solid #c7c7c7;
    border-right: 1px solid #c7c7c7;
    border-left: 1px solid #c7c7c7;
    border-bottom: 1px solid #3d3d3d;
    -webkit-border-top-left-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    -moz-border-radius-topleft: 3px;
    -moz-border-radius-topright: 3px;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    display: block;
    text-align: center;
    padding-top: 1px;
    padding-bottom: 1px;
    background-color: #FFF;
}

.mois_calendrier_inactif {
    width: 45px;
    text-align: center;
    padding-top: 3px;
    padding-bottom: 3px;
    border-left: 1px solid #c7c7c7;
    border-right: 1px solid #c7c7c7;
    border-bottom: 1px solid #c7c7c7;
    -webkit-border-bottom-right-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    -moz-border-radius-bottomright: 3px;
    -moz-border-radius-bottomleft: 3px;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    font-size: 12px;
    background-color: #d2d2d2;
}

.form-load-doc .form-control-feedback {
    top: 0px !important;
}

.form-load-doc .input-group .form-control {
    border-bottom-right-radius: 3px !important;
    border-top-right-radius: 3px !important;
}

.form-input-text .form-control-feedback {
    top: 10px !important;
    right: 10px !important;
}

.form-input-text .form-control {
    border-bottom-right-radius: 3px !important;
    border-top-right-radius: 3px !important;
}

.has-error .form-control-feedback {
    top: 0;
}

@media (min-width: 2px) {
    .form-inline .form-load-doc .input-group .input-group-btn {
        width: 1%;
    }

    .bloc_popup_photo_realisation .form-inline .form-control {
        width: 100%;
    }

    .bloc_popup_photo_realisation .form-inline.select-date .form-control {
        width: auto;
    }
}
#footer_espace_membre .form-control {
    height: 40px;
}

.popup_historique {
    width: 750px;
    border: 1px solid #bfbfbd;
    background: #ffffff;
    /* Old browsers */
    padding: 0px 20px 20px 20px;
    float: left;
}

.ss_title_popup {
    background-image: url('../../images/membre/fleche_titre.png');
    background-repeat: no-repeat;
    background-position: 0px 12px;
    margin-top: 30px;
    margin-left: 15px;
    border-bottom: 1px solid #bfbfbd;
    float: left;
    padding-left: 15px;
    padding-bottom: 10px;
    width: 650px;
    font-size: 24px;
    color: #303030;
    margin-bottom: 30px;
}

.ss_title_popup_hist {
    background-image: url('../../images/membre/fleche_titre.png');
    background-repeat: no-repeat;
    background-position: 0px 12px;
    margin-top: 30px;
    border-bottom: 1px solid #bfbfbd;
    float: left;
    padding-left: 15px;
    padding-bottom: 10px;
    width: 800px;
    font-size: 24px;
    color: #303030;
    margin-bottom: 30px;
}

.ss_title_popup_doc {
    background-image: url('../../images/membre/fleche_titre.png');
    background-repeat: no-repeat;
    background-position: 0px 12px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 15px;
    border-bottom: 1px solid #bfbfbd;
    padding-left: 15px;
    padding-bottom: 10px;
    width: 520px;
    font-size: 24px;
    color: #303030;
}

.block-input-form {
    padding: 10px;
}

.txt_info_entreprise_popup {
    line-height: 18px;
    font-size: 14px;
    color: #303030;
    margin-top: 10px;
    float: left;
    text-align: justify;
    width: 650px;
    margin-left: 15px;
    margin-right: 15px;
}

.col_form_coordonnee_popup {
    width: 200px;
    float: left;
    margin-top: 20px;
    margin-left: 15px;
}

.col_form_coordonnee_popup .select2-container {
    width: 200px !important;
}

.col_form_coordonnee_popup .select2-container .select2-choice {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    height: 34px;
    line-height: 30px;
    padding: 0 0 0 11px;
}

.col_form_coordonnee_popup .select2-container .select2-choice .select2-arrow {
    height: 26px;
    position: absolute;
    background: #fff;
    border-left: none;
}

.col_form_coordonnee_popup .select2-container .select2-choice .select2-arrow b {
    border-color: #555 transparent transparent transparent;
    border-style: solid;
    border-width: 6px 3px 0 3px;
    height: 0;
    margin-left: 4px;
    position: absolute;
    top: 50%;
    width: 0;
}

.col_form_coordonnee_1_popup {
    width: 200px;
    float: left;
    margin-left: 20px;
    margin-top: 20px;
}

.select_coordonnee_popup {
    padding: 4px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    width: 200px !important;
    font-family: 'open_sansregular', Arial, Helvetica, sans-serif;
    margin-bottom: 10px;
}

.input_coordonnee_popup {
    padding: 5px;
    border: 1px solid #c3c3c1;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    width: 200px !important;
    font-family: 'open_sansregular', Arial, Helvetica, sans-serif;
    margin-bottom: 10px;
}

.bt_enregistrer_coordonnee_popup {
    width: 190px;
    margin-left: 250px;
    float: left;
}

.info_entreprise_textarea {
    width: 640px;
    font-family: 'open_sansregular', Arial, Helvetica, sans-serif;
    border: 1px solid #c3c3c1;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    height: 120px;
    color: #5a5a5a;
    padding: 10px;
    margin-top: 10px;
}

.photo_realisations_upload {
    float: left;
    margin-left: 120px;
}

.bt_annuler_photo {
    position: relative;
    left: -25px;
    top: 10px;
}

#fiche_conseiller ul {
    margin-bottom: 5px;
}

.textarea-coordonnee {
    width: 100% !important;
}

.popup_coordonnee {
    width: 700px;
    border: 1px solid #bfbfbd;
    height: 825px;
    background: #ffffff;
    /* Old browsers */
}

.popup_description {
    width: 700px;
    background: #ffffff;
    /* Old browsers */
}

.form-horizontal .popup_coordonnee .form-group {
    margin-left: 0;
}

.bt_bleu {
    color: #FFF;
    text-align: center;
    margin-top: 10px;
    padding: 10px;
    font-family: 'open_sansregular', Arial, Helvetica, sans-serif;
    background: #28a3b2; /* Old browsers */
    cursor: pointer;
    font-size: 16px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    font-weight: 600;
    font-weight: normal;
}

a.bt_bleu {
    background: #28a3b2; /* Old browsers */
    text-decoration: none !important;
    color: #FFF;
    font-weight: normal;
}

a.bt_bleu:hover, a.bt_bleu:active, a.bt_bleu:visited {
    background: #228C98;
    text-decoration: none !important;
    color: #FFF !important;
}

.bt_enregistrer_coordonnee_popup {
    width: 190px;
    margin-left: 250px;
    float: left;
}

.font {
    font-family: 'fjalla_oneregular', sans-serif;
}

.ss_title_popup {
    background-image: url('../../images/membre/fleche_titre.png');
    background-repeat: no-repeat;
    background-position: 0px 12px;
    margin-top: 30px;
    margin-left: 15px;
    border-bottom: 1px solid #bfbfbd;
    float: left;
    padding-left: 15px;
    padding-bottom: 10px;
    width: 650px;
    font-size: 24px;
    color: #303030;
    margin-bottom: 30px;
}

font {
    font-family: 'fjalla_oneregular', sans-serif;
}

body {
    font-family: 'open_sansregular', Arial, Helvetica, sans-serif;
}

strong {
    font-weight: 600;
}

#wrapper {
    position: absolute;
    width: 100%;
    z-index: 0;
}

#etiquette {
    left: 100%;
    margin-left: -95px;
    top: 250px;
    z-index: 99;
    width: 179px;
    height: 122px;
    position: fixed;
}

.title_etiquette {
    font-size: 16px;
    color: #FFF;
    margin-top: 45px;
    margin-left: 30px;
    margin-right: 40px;
    float: left;
}

.txt-etiquette {
    color: #FFF;
    text-align: center;
    float: left;
    width: 200px;
    display: block;
    margin-top: 7px;
    margin-left: 5px;
    line-height: 16px;
}

a.lien-bulle:hover {
    background: none;
    z-index: 50;
}

a.lien-bulle .bulle {
    display: none;
}

a.lien-bulle {
    text-decoration: none;
}

a.lien-bulle:hover .bulle {
    display: block;
    position: relative;
    right: 230px;
    top: -100px;
    font-family: 'open_sansregular', arial, verdana, sans-serif;
    font-size: 14px;
    line-height: 18px;
    font-weight: normal;
    width: 230px;
    background: white;
    padding: 10px;
    color: #3c3c3c;
    border: 2px solid #28a3b2;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    text-decoration: none;
}

.bulle p {
    display: block;
}

.bt-telecharge {
    background-color: #28a3b2;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    color: #FFF;
    text-transform: uppercase;
    margin: 10px auto 0px auto;
    display: block;
    text-align: center;
    padding: 10px;
    background-image: url('/images/fleche.png');
    background-position: 170px 15px;
    background-repeat: no-repeat;
    font-weight: 600;
    cursor: pointer;

}

.close-app {
    position: absolute;
    display: block;
    top: 5px;
    left: 77px;
    z-index: 900;
}

.bloc_popup_doc {
    width: 550px;
    border: 1px solid #bfbfbd;
    background: #ffffff; /* Old browsers */
}

.title_photo_profil {
    text-align: center;
    margin-top: 20px;
    font-size: 24px;
}

.note_complementaire {
    color: #ff580c;
    font-style: italic;
    font-size: 14px;
    width: 100%;
    float: left;
    margin-bottom: 15px;
    margin-top: 15px;
    background-color: #ffd9cc;
    border: 1px solid #ff580c;
    padding: 10px;
}

.note_popup {
    margin-left: 100px;
    width: 300px !important;
    margin-top: 0px !important;
}

.note_pdf {
    float: left;
    margin-left: 15px;
    margin-top: 20px;
    width: 520px;
}

.bt_orange_1 {
    font-size: 16px;
    padding: 10px;
    font-weight: 600;
    font-family: 'open_sansregular', Arial, Helvetica, sans-serif;
    border: none;
    color: #FFF !important;
    text-align: center;
    background-color: #ff580c !important;
    border-radius: 3px;
    cursor: pointer;
    font-weight: normal;
}

.btn-file {
    position: relative;
    overflow: hidden;
}

.btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    background: red;
    cursor: inherit;
    display: block;
}

.bt_enregistrer_doc {
    float: right;
    margin: 20px 15px 20px 0;
}

.bt_bleu {
    color: #FFF;
    text-align: center;
    margin-top: 10px;
    padding: 10px;
    font-family: 'open_sansregular', Arial, Helvetica, sans-serif;
    background: #28a3b2; /* Old browsers */
    cursor: pointer;
    font-size: 16px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    font-weight: 600;
    font-weight: normal;
}

a.bt_bleu {
    background: #28a3b2; /* Old browsers */
    text-decoration: none !important;
    color: #FFF;
    font-weight: normal;
}

a.bt_bleu:hover, a.bt_bleu:active, a.bt_bleu:visited {
    background: #228C98;
    text-decoration: none !important;
    color: #FFF !important;
}

.title-login {
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 2.3rem;
    line-height: 2.6rem;
    color: #474747;
    margin-right: -15px;
}

#CGVModal .modal-body{
    height: 300px;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.05);
}

#CGVModal .modal-header{
    font-size: 1.25em;
    justify-content: center;
}

#CGVModal .modal-body .heading:first-child{
    margin-top: 0;
}

#CGVModal .modal-footer{
    align-items: start;
    flex-direction: column;
}

#CGVModal .modal-footer .form-check{
    margin-bottom: 10px;
}

#CGVModal .modal-footer .form-check-input{
    margin-top: 0;
}

#CGVModal .modal-footer .form-check-label {
    padding-left: 5px;
}

#CGVModal .modal-footer small {
    margin-left: 0;
}

.lds-dual-ring {
    display: inline-block;
    width: 13px;
    height: 13px;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    border: 2px solid #ff580c;
    border-color: #ff580c transparent #ff580c transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
form[name="reset_password"] input {
    height: 45px;
}

#filter-projects .select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #ff804b;
    color: white;
}

#filter-projects .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #ff804b;
    border: 1px solid #ff804b;
    border-radius: 4px;
    cursor: default;
    float: left;
    margin-right: 5px;
    margin-top: 5px;
    padding: 5px 5px;
    color: white;
}

#filter-projects .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: white;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    margin-right: 2px;
}

#filter-projects .hidden {
    display: none;
    visibility: hidden;
}

#filter-projects .loader {
    border: 16px solid #f1f1f1;
    border-top: 16px solid #ff580c;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    animation: spin 1s linear infinite;
    position: absolute;
    left: calc(50% - 60px);
    top: 160px;
    z-index: 500;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

#filter-projects.running {
    opacity: 0.3;
    pointer-events: none;
    cursor: default;
}

#form-chantier .select2-container {
    width: 100%!important;
    top: 7px;
}

#form-chantier .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #555555;
}

#form-chantier .select2-selection {
    font-size: 18px;
    padding-left: 10px;
    height: 44px;
    border: none;
}

#form-chantier .select2-selection__rendered {
    line-height: 44px;
}

#form-chantier .select2-selection__arrow {
    display: none;
}

#form-chantier .select2 .select2-selection--single {
    border-radius: 4px;
}

a.loadable, button.loadable  {
 position: relative
}

a.loading, button.loading  {
    color: transparent;
    opacity: 1;
    pointer-events: none;
    transition: .1s ease;
}

a.loading:before, button.loading:before {
    content: "";
    position: absolute;
    border: 0.2em solid rgba(0, 0, 0, .15);
    border-radius: 100%;
    height: 1.2em;
    width: 1.2em;
    margin: -0.6em 0 0 -0.6em;
    left: 50%;
    top: 50%;
}

a.loading:after, button.loading:after {
    animation: spin .6s linear;
    animation-iteration-count: infinite;
    border-radius: 100%;
    border-style: solid;
    border-width: 0.2em;
    box-shadow: 0 0 0 0.1rem transparent;
    content: "";
    left: 50%;
    top: 50%;
    position: absolute;
    border-color: #fff transparent transparent;
    height: 1.2em;
    width: 1.2em;
    margin: -0.6em 0 0 -0.6em;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}